@import "../../styles/vars";

.loading-indicator {
  display: inline-block;
  width: $font-size;
  height: $font-size;
  border-radius: $font-size/2;
  border: 3px solid;
  border-color: currentColor transparent currentColor currentColor;
  position: relative;
  top: 2px;
  transform: rotate(-90deg);
  animation: rotate 800ms ease-in-out infinite;
  // @each $color in map-keys($colors) {
  //   &.btn-#{$color} {
  //     border-color: map-get($colors, $color)
  //       transparent
  //       map-get($colors, $color)
  //       map-get($colors, $color);
  //   }
  // }
}

@keyframes rotate {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(270deg);
  }
}
