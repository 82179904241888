@import "../../styles/vars";

.custom-select--aspect {
  display: inline-block;
  line-height: 3rem;
  width: 3rem;
  border: 1px solid $line-color;
  border-radius: 1.5rem;
  text-align: center;
  background-color: #fff;
  color: $text-color;
  font-size: 1.5rem;
  font-weight: $font-weight-bold;
}

.custom-select--select {
  padding: 0.5rem 2.5rem 0.5rem 0;
  border: 0 none;
  background-color: transparent;
  // make it look like a label
  font-family: $font-family;
  font-weight: $font-weight-bold;
  font-size: 1em;
  color: currentColor;
  width: 100%;
  min-width: 0;
  cursor: pointer;
  &:focus {
    @include focus();
  }
}
