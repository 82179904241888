@import "../../styles/vars";

$bubble-size: 80px;
.bubble-link {
  position: relative;
  z-index: 2;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: $bubble-size;
  height: $bubble-size;
  border-radius: ($bubble-size/2);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: $font-size-small;
  line-height: 1.5;
  transition: transform 150ms ease-in, box-shadow 150ms ease-in;
  svg.icon {
    font-size: 1.5em;
    stroke-width: 2.5;
  }
  &:after {
    content: "";
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
  }
  &.radiate:after {
    animation: radiate 300ms ease-out;
  }
  &:hover {
    transform: scale(1.1);
    animation: pulsate 400ms ease-out infinite alternate;
    @include hovershadow();
  }
}

@mixin bubble-link-color($which) {
  background-color: $which;
  color: contrasting-text($which);
  &:after {
    background-color: $which;
  }
  &:hover {
    background-color: lighten-darken($which, 8%);
    color: contrasting-text($which);
  }
}

@each $color in map-keys($colors) {
  .bubble-link-#{$color} {
    @include bubble-link-color(map-get($colors, $color));
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes radiate {
  30% {
    transform: scale(3);
  }
  60% {
    transform: scale(4);
  }
  100% {
    transform: scale(10);
    opacity: 0;
  }
}
