// Project SCSS variables
//
// Layout
$grid-columns: 12;
$grid-gutter: 40px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
$max-width: 1100px;

// Type
$font-size: 20px;
$font-size-small: 0.8em;
$font-size-large: 1.25em;
$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, "Sans Serif",
  Icons /*!System*/;
$alt-font: "Phosphate Inline", sans-serif; // this is for an alternative font
$font-weight-light: 300;
$font-weight-normal: 300;
$font-weight-bold: 700;
$font-scale: 1.6;
$line-height: $font-scale;
$text-margin-bottom: 1.5rem;

// Background colors
$page-background: #fff;
$footer-background: #fff;
$page-background-dark: #1e1e1e;
$footer-background-dark: #1e1e1e;

// Text colors
$text-color: #555;
$text-light: minimum-contrast-text(#ddd, $page-background);
$text-color-dark: #ccc;
$text-light-dark: minimum-contrast-text(#999, $page-background);

// Design colors
$brand-primary: #ed40c5;
$brand-secondary: #3ad8b6;
$brand-info: #64bcff;
$brand-success: #c1e88d;
$brand-warning: #eff00a;
$brand-danger: #d94137;
$brand-light: #ccc;
$brand-dark: $text-color;
$accent: $brand-primary;
$pop: #ffb240;

$colors: (
  primary: $brand-primary,
  secondary: $brand-secondary,
  info: $brand-info,
  success: $brand-success,
  warning: $brand-warning,
  danger: $brand-danger,
  light: $brand-light,
  dark: $brand-dark,
  pop: $pop,
);

$selection: fade-out($pop, 0.3);
$link-color: $accent;
$link-hover: $pop;

// Indicator colors
$color-info: $pop;
$screen-info: change-color($color-info, $lightness: 90%);
$color-success: #1d9428;
$screen-success: change-color($color-success, $lightness: 90%);
$color-warning: #d4ae2f;
$screen-warning: change-color($color-warning, $lightness: 90%);
$color-danger: #c0343b;
$screen-danger: change-color($color-danger, $lightness: 90%);

// Form colors
$form-color-button: $link-color;
$form-color-focus: $pop;

// Line colors
$line-color: $brand-light;
$line-color-heavy: #999;
$line-color-dark: #666;
$line-color-dark-heavy: #ccc;

// Radii
$master-radius: 0;
$form-radius: $master-radius;
$button-radius: $form-radius;
$box-radius: $master-radius;

// Padding
$padding-menu: 0.75em 1em;
$padding-button: 0.5em 1em;
$padding-btn: $padding-button;
$padding-input: 0.5rem;
$padding-box: 0.5rem;

// Screens
$screen: #ddd;
$screen-lt: #fff;
$screen-dk: #444;

$animation-duration: 100ms;

// Mixies
@import "modules/_mixins";

// Functions
@import "modules/_functions";
