@import "../../styles/vars";

$grid-color-1: #fff;
$grid-color-2: $line-color;

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem;
  .image {
    position: relative;
    border: 8px solid $line-color-heavy;
    background-color: $grid-color-1;
    background-image: linear-gradient(45deg, $line-color 25%, transparent 25%),
      linear-gradient(-45deg, $line-color 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, $line-color 75%),
      linear-gradient(-45deg, transparent 75%, $line-color 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    img {
      display: block;
      min-width: 72px;
    }
    .dl-link {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%) rotate(-5deg);
    }
  }
  .aside {
    padding: 1rem 2rem;
  }
}

.finger-pointy {
  animation: pointy 500ms linear infinite alternate;
}

@media only screen and (max-width: breakpoint(sm)) {
  .image-container {
    .image .dl-link {
      transform: translate(65%, 55%) rotate(-5deg);
    }
    .aside {
      padding: 1rem;
      svg {
        width: 50px;
      }
    }
  }
}

@keyframes shakey {
  0% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@keyframes pointy {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0.75rem);
  }
}
